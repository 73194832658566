import React, { useCallback, useReducer, Reducer, useMemo, Fragment } from 'react';
import * as os from 'os';
import { Button, Table, Input, Icon, Header, Modal, Checkbox } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import PageMenu from '../pagemenu/pagemenu';
import { genericSort } from '../../utilities';
import { DataTableProps, DataTableState, DataTableAction } from './types';
import { reducer } from './reducer';

function DataTable<T>(props: DataTableProps<T>): React.ReactElement<DataTableProps<T>> {

    const columnLimit = useMemo( () => props.noOfDefaultColumns || props.columns.length, [ props.noOfDefaultColumns, props.columns ]);

    const [ state, dispatch ] = useReducer<Reducer<DataTableState<T>, DataTableAction<T>>>(reducer, {
        sort: {
          column: props.sortColumn || 0,
          asc: !!props.sortDescending,
        },
        filter: '',
        page: props.page || 0,
        columns: (
          props.columns.length <= columnLimit
            ? props.columns
            : (props.defaultColumnsToShow ? props.columns.filter(
                 c => props.defaultColumnsToShow?.includes(c.header)
              ) : props.columns.slice(0, columnLimit))
        ),
        showAddColumnsModal: false
    });

    const search = useCallback( (row: T, value: string): boolean => {
        
        /* look for any matching searchable column */
        for (const i in state.columns) {
            
          /* get column; look for a user-defined search function first */
          const column = state.columns[i];
          if (column.unsearchable) continue;
          if (column.search && column.search(row)) return true;

          /* fall back to searching the column value */
          const rvalue = column.value(row).toString();
          if (rvalue.toLowerCase().includes(value.toLowerCase())) return true;
          
      }

      /* no matching column for this row */
      return false;

    }, [ state.columns ]);

    const sort = useCallback( (rows: T[]): T[] => {

        /* look for a user-defined sort function for this column or fall back
            to generic string/number sorting */
        const sortf = state.columns[state.sort.column].sort || (
            ((a, b) => genericSort(state.columns[state.sort.column].value(a), state.columns[state.sort.column].value(b)))
        );
        const sortedRows = rows.sort(sortf);
        if (!state.sort.asc) sortedRows.reverse();

        return sortedRows;

    }, [ state.columns, state.sort ]);

    const displayRows = useCallback( (sortedRows: T[], filterValue: string): T[] => (
        filterValue === '' ? [ ...sortedRows ] : sortedRows.filter(row => search(row, filterValue))
    ), [ search ]);
    const displayedRows = useMemo( () => (
      sort(displayRows(props.rows, state.filter || props.search || ""))
    ), [ displayRows, sort, state.filter, props.rows, state.sort, props.search ]);

    const download = useCallback( () => {
        const data = state.columns.map(col => col.header).join('\t') + os.EOL + (
            displayedRows.map( (row: T) => (
                state.columns.map(col => col.value(row)).join('\t')
            ))
        ).join(os.EOL) + os.EOL;
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        const blob = new Blob([ data ], { type: "text/plain" });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = props.downloadFileName || "table.tsv";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }, [ state.columns, displayedRows ]);

    const itemsPerPage = props.itemsPerPage || 10;

    const page = props.page || state.page;
    const setPage = useCallback((page: number) => {
      props.setPage?.(page);
      dispatch({ type: 'pageChanged', page });
    }, [props.setPage, dispatch]);
    
    return (
      <>
        <Header textAlign="left">{props.tableTitle}</Header>
        <Table celled selectable={props.selectable}>
          { !props.hideHeader && (
            <Table.Header>
              { props.searchable && (
                  <Table.Row>
                    <Table.HeaderCell colSpan={state.columns.length} textAlign="right">
                      {props.showMoreColumns && props.columns.length > (props.noOfDefaultColumns || 5) && <Button onClick={() => dispatch({ type: 'modalChanged', showAddColumnsModal: true }) }>Add Columns</Button>}
                      <Button onClick={download}><Icon name="download" /> download</Button>
                      <Input
                        icon={{ name: 'search' }}
                        onChange={e => dispatch({ type: 'searchChanged', value: e.target.value })}
                        placeholder="filter items"
                      />
                    </Table.HeaderCell>
                  </Table.Row>
              )}
              <Table.Row>
                { state.columns.map((column, i) => (
                    <Table.HeaderCell
                      key={`${column.header}${i}`}
                      onClick={() => dispatch({ type: 'sortChanged', sortColumn: i }) }
                    >
                      {column.headerRender ? column.headerRender() : column.header}&nbsp;
                      {i === state.sort.column &&
                      (state.sort.asc ? <Icon name="caret up" /> : <Icon name="caret down" />)}
                    </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
          )}
          <Table.Body>
            { props.rows.length === 0 ? (
                <Table.Row>
                  <Table.Cell>
                    {props.emptyText || "No data available."}
                  </Table.Cell>
                </Table.Row>
            ) : (
              displayedRows.slice(page * itemsPerPage, (page + 1) * itemsPerPage).map((row, i) => (
                <Table.Row
                  key={'row' + i}
                  onClick={() => props.onRowClick && props.onRowClick(row, i + page * itemsPerPage)}
                  onMouseOver={() => { dispatch({ type: "mousedOver", index: i })}}
                  onMouseOut={() => dispatch({ type: "mousedOver", index: undefined })}
                  style={{ backgroundColor: state.mousedOver === i ? "#f2f2f2" : undefined }}
                  as={props.rowLink && Link}
                  to={props.rowLink && props.rowLink(row, i)}
                >
                  { state.columns.map((column, j) => (
                      <Table.Cell key={`${column.header}${i}_${j}`}>
                        {column.render ? column.render(row) : column.value(row)}
                      </Table.Cell>
                  ))}
                </Table.Row>
            )))}
          </Table.Body>
          { !props.hidePageMenu && (
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan={state.columns.length}>
                    { displayedRows.length !== props.rows.length && (
                      `Showing ${displayedRows.length} matching rows of ${props.rows.length} total.`
                    )}
                    <PageMenu
                      length={Math.ceil((displayedRows.length * 1.0) / itemsPerPage)}
                      selected={page}
                      onPageSelect={setPage}
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
          )}
        </Table>
        <Modal open={state.showAddColumnsModal} onClose={()=> dispatch({ type: 'modalChanged', showAddColumnsModal: false })}>
        <Modal.Header>Add Columns</Modal.Header>
        <Modal.Content scrolling>
            {(props.defaultColumnsToShow ? props.columns.filter((c)=>!props.defaultColumnsToShow?.includes(c.header)) : props.columns.slice((props.noOfDefaultColumns || 5),props.columns.length)).map((col, i)=> (
                <Fragment key={i}>
                <Checkbox
                  label={col.header}
                  checked={state.columns.find(c => c.header === col.header) !== undefined}
                  onChange={(_ , data) => {
                    if (!!data.checked && props.columns.find(c => c.header === col.header))
                      dispatch({ type: 'columnsChanged', columns: [ ...state.columns, col ] })
                    else
                      dispatch({ type: 'columnsChanged', columns: state.columns.filter(u => u.header !== col.header) })
                  }}
                />
                <br/>
                </Fragment>
            ))}
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={()=> dispatch({ type: 'modalChanged', showAddColumnsModal: false })}>Cancel</Button>
        </Modal.Actions>
        </Modal>
      </>
    );

}
export default DataTable;
